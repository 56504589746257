.expedientes-header {
  background-color: rgb(207, 207, 207);
}

.alerta-modal{
  margin-bottom: 20px;
}

.expedientes .table.gy-7 td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.btn-download{

  background-image: url('../../../../public/media/icons/icn-dowload.png');
  background-repeat:no-repeat; 
  background-position: right 10px center; 
  background-size: 20px;
  padding-right: 40px !important;

}
.btn-bulk-load{
  background-color: #cc6553 !important;
  color: white !important;
}

.btn-bulk-load:hover{
  background-color: #c4604e !important;
}

.btn-bulk-load:active{
  background-color: #c4604e !important;
}




