.alerta-modal{
    margin-bottom: 20px;
  }


  .s-hidden {
    visibility:hidden;
    padding-right:10px;
}
.select {
    cursor:pointer;
    display:inline-block;
    position:relative;
    font:normal 11px/22px Arial, Sans-Serif;
    color:black;
    border:1px solid #ccc;


    display: block;
    width: 100%;
    padding: 0.775rem 3rem 0.775rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #5E6278;
    background-color: #ffffff;
   
    background-position: right 1rem center;
    background-size: 16px 12px;
    border: 1px solid #E4E6EF;
    border-radius: 0.475rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;

    
}
.styledSelect {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:white;
    padding:10px;
    font-weight:bold;

    
}
.styledSelect:after {
    content:"";
    width:0;
    height:0;
    border:5px solid transparent;
    border-color:black transparent transparent transparent;
    position:absolute;
    top:15px;
    right:6px;

    
}
.styledSelect:active, .styledSelect.active {
    background-color:#eee;
}
.options {
    display:none;
    position:absolute;
    top:100%;
    right:0;
    left:0;
    z-index:999;
    margin:0 0;
    padding:0 0;
    list-style:none;
    border:1px solid #ccc;
    background-color:white;
    -webkit-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
}
.options li {
    padding:0 6px;
    margin:0 0;
    padding:5px 10px;
}

.options li:nth-child(1){
    pointer-events: none;
    opacity: 0.6;
}

.options li:nth-child(2){
    border: 1px solid #bd0003 !important;
    background-color: rgba(183, 0, 3, 0.2) !important;
}

.options li:nth-child(3){
    background-color: #e1e1e1;
}


.options li:hover {
    background-color:#39f;
    color:white;
}


.cuadrado {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 1px solid #000;
    border: 1px solid #bd0003 !important;
    background-color: rgba(183, 0, 3, 0.2) !important;
  }

  li::marker {
    color: hotpink;
  }
  
  li:first-child::marker {
    font-size: 5rem;
  }