.inicio-search-box {
  width: 250px;
}

.btn.btn-dia{
  background-color: #3f4254;
  color: white;

}

.card-expediente {
  background-color: #e1e1e1;
}

.card-expediente:hover {
  cursor: pointer;
}

.card-expediente .card-body {
  padding: 16px 16px 16px 16px;
}

.card-vencida {
  border: 1px solid #bd0003 !important;
  background-color: rgba(183, 0, 3, 0.2) !important;
}

.expediente-detalle {
  background-color: #f0f0f0;
  border: 1px solid #e1e1e1;
  border-top: 0px;
  margin-top: -8px;
}

.bullet-boletin-status {
  font-size: 52px;
  line-height: 50px;
  position: relative;
  top: -2.5px;
  vertical-align: middle;
  color: green;
}

.page-number {
  margin-left: 4px;
  margin-right: 4px;
}

.page-number.active {
  font-weight: 700;
}

.inicio-search-box {
  border: 2px solid #a1a5b7 !important;
    padding: 7px;
    border-radius: 10px !important;
    top: 3px;
    position: relative;
    
}

.mr-10{
  margin-right: 20px;
}

.svg-icon.svg-icon-lg-1 svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
  left: 5px;
  position: relative;
  top: 3px;
}

.alerta-modal{
  margin-bottom: 20px;
}
