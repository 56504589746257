
  
  .label-red {
    padding: 10px 40px;
    border-radius: 10px;
    color: #F96450;
    background-color: rgba(249,100,80,.1);
    border: 1px solid #F96450;
    position: absolute;
    width: auto;
    left: 20px;
    top: 12px;
    
  }
  
  